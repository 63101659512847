import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="title" content="Dataandgrow - Innovating the Future of IT & Technology" />
      <meta 
        name="description" 
        content="Dataandgrow offers innovative IT consulting, AI, ML, VR/AR, custom software development, cloud services, DevOps, and big data solutions to empower businesses." 
      />
      <meta 
        name="keywords" 
        content="
          Dataandgrow, IT consulting, AI solutions, Machine Learning, Cloud Computing, Custom Software Development, Big Data Analytics, 
          Unity Game Development, DevOps Services, Virtual Reality, Augmented Reality, Data Science, 
          Digital Transformation, Cybersecurity, Blockchain, Data Analytics, IT Services, Digital Marketing, 
          Software Development, IT Strategy, Business Intelligence, Mobile App Development, Cloud Services, 
          Internet of Things, IoT, Digital Adoption, Predictive Analytics, Data Engineering, 
          Agile Development, DevOps Practices, E-learning Solutions, Smart Technologies, 
          Digital Health Solutions, FinTech Solutions, EdTech Solutions
        " 
      />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow" />
      <meta name="bingbot" content="index, follow" />
      <meta name="yahooBot" content="index, follow" />
      <meta name="author" content="Dataandgrow" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="1 day" />
      <meta name="distribution" content="Global" />
      <meta name="rating" content="General" />
      <meta name="target" content="all" />
      <meta name="copyright" content="© 2024 Dataandgrow. All Rights Reserved." />

      {/* Dublin Core Meta Tags */}
      <meta name="DC.title" content="Dataandgrow - IT Services and Consulting in New Delhi" />
      <meta 
        name="DC.description" 
        content="Offering innovative IT solutions with a focus on AI, ML, cloud services, VR/AR, and DevOps to help businesses thrive." 
      />
      <meta name="DC.creator" content="Dataandgrow" />
      <meta name="DC.language" content="en" />
      <meta name="DC.subject" content="IT Services, Consulting, Cloud, Software Development, AI, Machine Learning, Data Science" />
      <meta name="DC.publisher" content="Dataandgrow" />
      <meta name="DC.coverage" content="Worldwide" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="Dataandgrow - Leading the Future of IT" />
      <meta 
        property="og:description" 
        content="Transforming the IT industry with AI, cloud services, DevOps, and custom software solutions. We innovate to help businesses grow." 
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dataandgrow.com" />
      <meta property="og:image" content="https://www.dataandgrow.com/logo.jpg" />
      <meta property="og:site_name" content="Dataandgrow" />
      <meta property="og:locale" content="en_US" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Dataandgrow" />
      <meta name="twitter:title" content="Dataandgrow - Future of IT Services" />
      <meta 
        name="twitter:description" 
        content="Leading innovation in AI, VR/AR, and cloud solutions. Offering custom software development and DevOps practices to accelerate business growth." 
      />
      <meta name="twitter:image" content="https://www.dataandgrow.com/logo.jpg" />

      {/* Google-Specific Meta Tags */}
      <meta name="google-site-verification" content="YOUR_GOOGLE_VERIFICATION_CODE" />
      <meta name="theme-color" content="#ffffff" />

      {/* Canonical URL */}
      <link rel="canonical" href="https://www.dataandgrow.com" />

      {/* Favicons */}
      <link rel="icon" href="https://www.dataandgrow.com/favicon.ico" type="image/x-icon" />
      <link rel="apple-touch-icon" href="https://www.dataandgrow.com/apple-touch-icon.png" />

      {/* JSON-LD Structured Data for SEO */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Dataandgrow",
          "url": "https://www.dataandgrow.com",
          "logo": "https://www.dataandgrow.com/logo.jpg",
          "sameAs": [
            "https://www.facebook.com/Dataandgrow",
            "https://twitter.com/Dataandgrow",
            "https://www.linkedin.com/company/dataandgrow"
          ],
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Manisha Building, Nehru Place",
            "addressLocality": "New Delhi",
            "addressRegion": "Delhi",
            "postalCode": "110019",
            "addressCountry": "IN"
          },
          "description": "Dataandgrow offers IT services, consulting, and custom software solutions. Specializing in AI, ML, VR, AR, and DevOps.",
          "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "Customer Service",
            "telephone": "+91 12345 67890",
            "areaServed": "IN"
          }
        })}
      </script>

      <title>Dataandgrow | Innovating the Future of Technology</title>
    </Helmet>
  );
};

export default SEO;
