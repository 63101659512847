import React, { useState, useEffect, useRef } from "react";
import styles from "../../css/Home/VideoSection.module.css";
import missionVideo from "../../video/mission.mp4";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";

// Reusable Video Section Component
const Video = ({ videoSrc, heading, subHeading }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const textRef = useRef();
  
  // Function to handle mouse move effect
  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const textElements = textRef.current?.querySelectorAll(`.${styles.overlay} *`);
    const moveX = (clientX / window.innerWidth - 0.5) * 15;
    const moveY = (clientY / window.innerHeight - 0.5) * 15;

    textElements?.forEach((element) => {
      element.style.transform = `translate(${moveX}px, ${moveY}px)`;
      element.style.transition = "transform 0.2s ease-out";
    });
  };

  useEffect(() => {
    const totalVideos = 1; // Update if more videos are added

    // If there are multiple videos, use setInterval for switching videos
    if (totalVideos > 1) {
      const interval = setInterval(() => {
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % totalVideos);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <section id="home" ref={textRef}>
      <div className={styles.imageSectionWrapper} onMouseMove={handleMouseMove}>
        <div
          className={`${styles.overlay} ${styles.slideFromBottom}`}
          style={{
            mixBlendMode: "normal",
            color: "#fff",
            textShadow: "4px 4px 10px rgba(0, 0, 0, 0.9)",
          }}
        >
          <h2 className={`${styles.heading} ${styles.slideFromBottom}`}>
            {heading}
          </h2>
          <div>
            <h4 className={`${styles.subHeading} ${styles.slideFromBottom}`}>
              {subHeading}
            </h4>
          </div>
        </div>

        <div className={styles.videoContainer}>
          <video
            src={videoSrc}
            className={`${styles.backgroundVideo} ${styles.slideFromBottom}`}
            autoPlay
            loop
            muted
            playsInline
          />
        </div>

        {/* Social Media Icons */}
        <div className={styles.shareButtonsContainer}>
          <FaFacebookF className={`${styles.shareButton} ${styles.facebook}`} />
          <FaTwitter className={`${styles.shareButton} ${styles.twitter}`} />
          <a
            href="https://www.linkedin.com/company/dataandgrow?trk=blended-typeahead"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn className={`${styles.shareButton} ${styles.linkedin}`} />
          </a>
          <FaInstagram className={`${styles.shareButton} ${styles.instagram}`} />
        </div>

        {/* Video Navigation Dots */}
        <div className={styles.dotContainer}>
          {[...Array(1)].map((_, index) => (
            <div
              key={index}
              className={`${styles.dot} ${index === currentVideoIndex ? styles.activeDot : ""}`}
              onClick={() => setCurrentVideoIndex(index)}
            ></div>
          ))}
        </div>

        {/* Centered Buttons */}
        <div className={styles.centerButtonsContainer}>
          <button className={`${styles.learnMoreButton} ${styles.zIndexTop}`}>
            Learn More
          </button>
        </div>
      </div>
    </section>
  );
};

// Default export with preset values for heading and subHeading
const VideoSection = () => {
  return (
    <Video 
      videoSrc={missionVideo}
      heading="Innovating the Future of Technology"
      subHeading="Our mission is to provide the IT industry with outstanding products and services, driven by a foundation of innovation"
    />
  );
};

export default VideoSection;
