import React, { useState, useEffect } from 'react';
import styles from "../../css/Home/Insight.module.css";
import image1 from "../../images/insight/1.png";
import image2 from "../../images/insight/2.png";
import image3 from "../../images/insight/3.png";

const Insight = () => {
    const [insights, setInsights] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [currentInsightIndex, setCurrentInsightIndex] = useState(0);

    // Dynamic data for sub-insights
    const subInsightData = [
        {
            title: "Enhancing Customer Experience with Generative AI",
            date: "November 08, 2024",
            image: image1,
            author: "Mayank",
            share: 321,
            views: 501
        },
        {
            title: "AI in Healthcare: Transforming Patient Care",
            date: "October 25, 2024",
            image: image2,
            author: "Vaibhav",
            share: 215,
            views: 789
        },
        {
            title: "The Future of AI in Finance",
            date: "September 15, 2024",
            image: image3,
            author: "Abhishek",
            share: 412,
            views: 680
        }
    ];

    // Automatically cycle through sub-insights every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentInsightIndex((prevIndex) => (prevIndex + 1) % subInsightData.length);
        }, 5000);
        
        return () => clearInterval(interval); // Clean up on unmount
    }, [subInsightData.length]);

    // Handle left and right arrow clicks
    const handleNext = () => {
        setCurrentInsightIndex((prevIndex) => (prevIndex + 1) % subInsightData.length);
    };

    const handlePrevious = () => {
        setCurrentInsightIndex((prevIndex) => 
            (prevIndex - 1 + subInsightData.length) % subInsightData.length
        );
    };

    // Current main insight to display
    const currentInsight = subInsightData[currentInsightIndex];

    const handleSubmit = (e) => {
        e.preventDefault();
        if (title && content) {
            const newInsight = { title, content };
            setInsights([...insights, newInsight]);
            setTitle('');
            setContent('');
        }
    };

    return (
        <section className={styles.mainInsightSection} id="insight">
            <div className={styles.insightSection}>
                {/* Background image */}
                <img className={styles.mainInsight} src={currentInsight.image} alt="Background" />
                
                {/* Overlay layer */}
                <div className={styles.overLayLight}>
                    <div className={styles.heading}>
                        Insights
                        <div className={styles.arrowl} onClick={handlePrevious}></div>
                        <div className={styles.arrowr} onClick={handleNext}></div>
                    </div>
                    
                    <div className={styles.mainInsightActive}>
                        <div className={styles.mainTitle}>{currentInsight.title}</div>
                        <div className={styles.mainStatusBar}>
                            <p>by <span className={styles.author}>{currentInsight.author}</span></p>
                            <span className={styles.postTime}>{currentInsight.date}</span>
                            <span className={styles.views}>{currentInsight.views} Views</span>
                            <span className={styles.share}>{currentInsight.share} Shared</span>
                        </div>
                        <div className={styles.mainSubTitle}>
                            Unlocking the full potential of generative AI demands more than a basic implementation—it requires building a complete ecosystem.
                        </div>
                        <button className={styles.mainButton}>Read More</button>
                    </div>
                </div>

                <div className={styles.overLayDark}>
                    <div className={styles.rightSubInsight}>
                        {subInsightData.map((insight, index) => (
                            <div key={index} className={styles.subInsight}>
                                <div className={styles.subInsightText}>
                                    <div className={styles.postTime}>{insight.date}</div>
                                    <div className={styles.rightSubInsightTitle}>{insight.title}</div>
                                    <div className={styles.authorname}>
                                        <p>by <span className={styles.author}>{insight.author}</span></p>
                                    </div>
                                </div>
                                <div className={styles.rightSubInsightContent}>
                                    <img className={styles.rightSubInsightImage} src={insight.image} alt={insight.title} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Insight;
