import React from 'react';
import { DonutCursorProvider, DonutConsumer } from 'react-donut-cursor';  // Import the donut cursor package

import Navbar from '../Components/Navbar';
import FirstVideoSection from './1_VideoSection'; 
import ContactSection from '../Components/Contact'; 
import CreativeTeamSection from './CreativeTeamSection';
import SecondAbout from './2_AboutSection';
import ThirdServices from './3_Services';
import FourthClient from './4_Client';
import FAQs from "./5_FAQs";
import Insight from "./Insight"; // Import the test insight component

const Home = () => {
  return (
    // Wrap the entire page with DonutCursorProvider to apply the custom cursor globally
    <DonutCursorProvider>
      {/* Wrap the components inside the DonutConsumer to apply the custom hover effect */}
      <DonutConsumer>
        <div>
          <Navbar />
          <FirstVideoSection />
          <SecondAbout />
          <ThirdServices />
          <FourthClient />
          <FAQs />
          <Insight /> 
          <CreativeTeamSection />
          <ContactSection />
        </div>
      </DonutConsumer>
    </DonutCursorProvider>
  );
};

export default Home;
