import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Pages/Components/Navbar'; // Check this path
import Home from './Pages/HomePage/Hindex'; // Assuming you renamed it to Home
import AboutPage from './Pages/AboutPage/Aindex';
import SEO from './Seo'; // Ensure this file exists
// import ServicesPage from './Pages/ServicesPage/Index'; // Ensure this file exists
// import ClientsPage from './Pages/ClientsPage/Index'; // Ensure this file exists
// import TeamPage from './Pages/TeamPage/Index'; // Ensure this file exists
// import ContactPage from './Pages/ContactPage/Index'; // Ensure this file exists

const App = () => {
  return (

    <Router>
      <SEO/>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<AboutPage />} /> */}
        {/* <Route path="/services" element={<ServicesPage />} />
        <Route path="/clients" element={<ClientsPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/contact" element={<ContactPage />} /> */}
      </Routes>
    </Router>
  );
};

export default App;

