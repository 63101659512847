import React, { useEffect, useState, useRef } from "react";
import styles from "../../css/Navbar.module.css";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../images/logo.png";
import _ from "lodash"; // Import lodash for debounce

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("#home");

  const dotRef = useRef(null);
  const outlineRef = useRef(null);

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [outlinePosition, setOutlinePosition] = useState({ x: 0, y: 0 });
  const [cursorVisible, setCursorVisible] = useState(true);
  const [cursorEnlarged, setCursorEnlarged] = useState(false);

  // Handle scrolling and update active link
  useEffect(() => {
    const handleScroll = _.debounce(() => {
      setIsScrolled(window.scrollY > 50);
      updateActiveLink();
    }, 100);

    const updateActiveLink = () => {
      const sections = [
        { id: "#home", element: document.getElementById("home") },
        { id: "#about", element: document.getElementById("about") },
        { id: "#services", element: document.getElementById("services") },
        { id: "#clients", element: document.getElementById("clients") },
        { id: "#testimonial", element: document.getElementById("testimonial") },
        { id: "#team", element: document.getElementById("team") },
        { id: "#contact", element: document.getElementById("contact") },
        { id: "#FAQs", element: document.getElementById("FAQs") },
        { id: "#insight", element: document.getElementById("insight") },
      ];

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        if (section.element) {
          const rect = section.element.getBoundingClientRect();
          if (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2
          ) {
            setActiveLink(section.id);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Cursor functionality
  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.pageX, y: e.pageY });
    };

    const toggleCursorSize = () => {
      if (cursorEnlarged) {
        dotRef.current.style.transform = "translate(-50%, -50%) scale(0.75)";
        outlineRef.current.style.transform = "translate(-50%, -50%) scale(1.5)";
      } else {
        dotRef.current.style.transform = "translate(-50%, -50%) scale(1)";
        outlineRef.current.style.transform = "translate(-50%, -50%) scale(1)";
      }
    };

    const toggleCursorVisibility = () => {
      if (cursorVisible) {
        dotRef.current.style.opacity = 1;
        outlineRef.current.style.opacity = 1;
      } else {
        dotRef.current.style.opacity = 0;
        outlineRef.current.style.opacity = 0;
      }
    };

    const updateOutlinePosition = () => {
      const { x, y } = cursorPosition;
      outlineRef.current.style.top = `${y}px`;
      outlineRef.current.style.left = `${x}px`;
    };

    document.addEventListener("mousemove", handleMouseMove);

    // Update the cursor position and size every time
    toggleCursorSize();
    toggleCursorVisibility();
    updateOutlinePosition();

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [cursorPosition, cursorEnlarged, cursorVisible]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (section) => {
    setActiveLink(section);
    setIsMenuOpen(false);
  };

  return (
    <>
      <nav
        className={`${styles.navbar} ${isScrolled ? styles.navbarScrolled : ""}`}
      >
        <div className={styles.logo}>
          <img src={logo} alt="Logo" className={styles.logoImage} />
        </div>
        <div className={styles.hamburger} onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <div
          className={`${styles.navLinks} ${isMenuOpen ? styles.showMenu : ""}`}
        >
          {[
            { href: "#home", text: "Home" },
            { href: "#about", text: "About Us" },
            { href: "#services", text: "Services" },
            { href: "#clients", text: "Our Clients" },
            { href: "#FAQs", text: "FAQs" },
            { href: "#insight", text: "Insight" },
            { href: "#team", text: "Our Team" },
            { href: "#contact", text: "Contact Us" },
          ].map(({ href, text }) => (
            <a
              key={href}
              href={href}
              className={`${styles.navLink} ${
                activeLink === href ? styles.activeLink : ""
              }`}
              onClick={() => handleLinkClick(href)}
            >
              {text}
            </a>
          ))}
        </div>
        <a href="#contact" className={styles.getStartedButton}>
          Get Started Now
        </a>
        {isMenuOpen && (
          <div className={styles.fullScreenMenu}>
            <div className={styles.menuContent}>
              {[
                { href: "#home", text: "Home" },
                { href: "#about", text: "About Us" },
                { href: "#services", text: "Services" },
                { href: "#clients", text: "Our Clients" },
                { href: "#FAQs", text: "FAQs" },
                { href: "#insight", text: "Insight" },
                { href: "#team", text: "Our Team" },
                { href: "#contact", text: "Contact Us" },
              ].map(({ href, text }) => (
                <a
                  key={href}
                  href={href}
                  className={styles.menuLink}
                  onClick={() => handleLinkClick(href)}
                >
                  {text}
                </a>
              ))}
            </div>
          </div>
        )}
      </nav>
      <div ref={outlineRef} className={styles.cursorDotOutline}></div>
      <div ref={dotRef} className={styles.cursorDot}></div>
    </>
  );
};

export default Navbar;
