import React, { useRef, useEffect } from "react";
import styles from "../../css/CreativeTeamSection.module.css";
import Abhishek from "../../images/team/1.jpg";
import Mayank from "../../images/team/2.jpg";
import Jyoti from "../../images/team/3.jpg";
import Vaibhav from "../../images/team/4.jpg";
import Nitesh from "../../images/team/5.jpg";

// Team Data
const teamData = [
  {
    name: "Abhishek",
    position: "Customer Success Manager",
    image: Abhishek,
    rank: 3,
    social: [
      { icon: "fab fa-facebook-f", link: "https://facebook.com" },
      { icon: "fab fa-twitter", link: "https://twitter.com" },
      { icon: "fab fa-google-plus-g", link: "https://plus.google.com" },
      { icon: "fab fa-linkedin-in", link: "https://linkedin.com" },
    ],
  },
  {
    name: "Mayank",
    position: "Software Architect",
    image: Mayank,
    rank: 1,
    social: [
      { icon: "fab fa-facebook-f", link: "https://facebook.com" },
      { icon: "fab fa-twitter", link: "https://twitter.com" },
      { icon: "fab fa-google-plus-g", link: "https://plus.google.com" },
      { icon: "fab fa-linkedin-in", link: "https://linkedin.com" },
    ],
  },
  {
    name: "Jyoti",
    position: "Software Developer",
    image: Jyoti,
    rank: 4,
    social: [
      { icon: "fab fa-facebook-f", link: "https://facebook.com" },
      { icon: "fab fa-twitter", link: "https://twitter.com" },
      { icon: "fab fa-google-plus-g", link: "https://plus.google.com" },
      { icon: "fab fa-linkedin-in", link: "https://linkedin.com" },
    ],
  },
  {
    name: "Vaibhav",
    position: "Lead Developer",
    image: Vaibhav,
    rank: 2,
    social: [
      { icon: "fab fa-facebook-f", link: "https://facebook.com" },
      { icon: "fab fa-twitter", link: "https://twitter.com" },
      { icon: "fab fa-google-plus-g", link: "https://plus.google.com" },
      { icon: "fab fa-linkedin-in", link: "https://linkedin.com" },
    ],
  },
  {
    name: "Nitesh",
    position: "Software Developer Intern",
    image: Nitesh,
    rank: 5,
    social: [
      { icon: "fab fa-facebook-f", link: "https://facebook.com" },
      { icon: "fab fa-twitter", link: "https://twitter.com" },
      { icon: "fab fa-google-plus-g", link: "https://plus.google.com" },
      { icon: "fab fa-linkedin-in", link: "https://linkedin.com" },
    ],
  },
];

// Sort Team by Position (Rank)
const sortedTeamData = [...teamData].sort((a, b) => a.rank - b.rank);

const CreativeTeamSection = () => {
  const sliderRef = useRef(null);
  let isDown = false;
  let startX;
  let scrollLeft;

  useEffect(() => {
    const slider = sliderRef.current;

    // Handle Dragging Events
    const handleMouseDown = (e) => {
      isDown = true;
      slider.classList.add(styles.active);
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDown = false;
      slider.classList.remove(styles.active);
    };

    const handleMouseUp = () => {
      isDown = false;
      slider.classList.remove(styles.active);
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2; // Adjust scroll speed
      slider.scrollLeft = scrollLeft - walk;
    };

    // Handle Auto Scroll
    const autoScroll = () => {
      if (slider) {
        const maxScrollLeft = slider.scrollWidth - slider.clientWidth;
        if (slider.scrollLeft >= maxScrollLeft) {
          slider.scrollLeft = 0;
        } else {
          slider.scrollLeft += 300; // Adjust scroll increment
        }
      }
    };

    const intervalId = setInterval(autoScroll, 5000); // Auto switch every 3 seconds

    slider.addEventListener("mousedown", handleMouseDown);
    slider.addEventListener("mouseleave", handleMouseLeave);
    slider.addEventListener("mouseup", handleMouseUp);
    slider.addEventListener("mousemove", handleMouseMove);

    return () => {
      slider.removeEventListener("mousedown", handleMouseDown);
      slider.removeEventListener("mouseleave", handleMouseLeave);
      slider.removeEventListener("mouseup", handleMouseUp);
      slider.removeEventListener("mousemove", handleMouseMove);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <section id="team" className={styles.teamSection}>
      <div className={styles.container}>
        <div className={styles.sectionHeading}>
        <h2 className={styles.mainFont}>Meet Our Team</h2>
<h2 className={styles.subFont}>Creative Minds, Happy Faces</h2>

        </div>

        <div className={`${styles.teamSlider}`} ref={sliderRef}>
          {sortedTeamData.map((member, index) => (
            <div key={index} className={styles.teamBox}>
              <div className={styles.teamImage}>
                <img
                  src={member.image}
                  alt={member.name}
                  onDragStart={(e) => e.preventDefault()} // Prevent default drag behavior
                />
              </div>
              <div className={styles.teamText}>
                <h5 className={styles.name}>{member.name}</h5>
                <span className={styles.altFont}>{member.position}</span>
                <div className={styles.teamSocial}>
                  <ul>
                    {member.social.map((social, idx) => (
                      <li key={idx}>
                        <a
                          href={social.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className={social.icon} aria-hidden="true"></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CreativeTeamSection;
