import React, { useEffect } from "react";
import styles from "../../css/TestimonialSection.module.css";
import { FaQuoteRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import client1 from "../../images/client/1.jpg";
import client2 from "../../images/client/2.jpg";
import client3 from "../../images/client/3.jpg";
import client4 from "../../images/client/4.jpg";

const TestimonialSection = () => { 
  const testimonials = [
    {
      text: "",
      name: "",
      image: client1,
    },
    {
      text: "",
      name: "",
      image: client2,
    },
    {
      text: "",
      name: "",
      image: client3,
    },
    {
      text: "",
      name: "",
      image: client4,
    },
    
    // Add more testimonials as needed
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <section id="testimonial">
      <div className={styles.testimonialSec} id="client">
        <div className={styles.rightOverlay}></div>
        <div className="container">
          <div className={styles.testimonialArea}>
            <div className="row">
              <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center text-center text-lg-left">
                <div className={styles.testimonialDetails}>
                  <h4 className={styles.heading}>
                  Our Clients'  <span>Success</span>
                  </h4>
                  <p className={styles.text}>
                  Your success is our mission, and we work tirelessly to ensure that satisfaction translates into meaningful and lasting achievements.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 offset-lg-1">
                <Carousel
                  responsive={responsive}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={4000} // Auto change in 4 seconds
                  customLeftArrow={
                    <FaChevronLeft className={styles.arrowLeft} />
                  }
                  customRightArrow={
                    <FaChevronRight className={styles.arrowRight} />
                  }
                  className="owl-theme"
                >
                  {testimonials.map((testimonial, index) => (
                    <div className={styles.item} key={index}>
                      <div className={styles.iconHolder}>
                      
                      </div>
                      <p className={styles.testimonialText}>
                        {testimonial.text}
                      </p>
                      <div className={styles.imgHolder}>
                        <img src={testimonial.image} alt={testimonial.name} />
                      </div>
                      <h4 className={styles.userName}>{testimonial.name}</h4>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
