import React, { useEffect, useRef } from "react";
import styles from "../../css/AboutSection.module.css";
import { FaLightbulb, FaBriefcase, FaHeart } from "react-icons/fa";

const AboutSection = () => {
  const textRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.inView);
            observer.unobserve(entry.target); // Stop observing once animation is triggered
          }
        });
      },
      {
        threshold: 0.3, // Trigger when 30% of the element is in view
      }
    );

    const elements = textRef.current?.querySelectorAll(
      `.${styles.animateOnScroll}`
    );
    elements?.forEach((el) => observer.observe(el));

    return () => {
      elements?.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <section className={styles.mainContainer} id="about" ref={textRef}>
    <div className={styles.leftContainer}>
    <div className={`${styles.titleFont}` }>Innovating the Future of Science and{" "}
              Technology!</div>
    
    <div className={`${styles.subFont}` }>At Dataandgrow, we are truly excited about harnessing the power of data to spark innovation and growth! Our mission is to provide wonderful digital solutions that uplift both businesses and individuals alike.</div> 
    <div className={`${styles.subFont}` }>By merging the latest technology with a genuine understanding of your needs, we craft delightful and customized solutions just for you.</div> 
    <div className={styles.learnMore}>Learn More</div>
    </div>
    <div className={styles.rightContainer}>
    <div className={styles.rightLargerContainer}><div className={styles.rightLargerContainerImage}></div></div>
    <div className={styles.rightLargerContainer}> <div className={styles.rightSmallerContainer}>
      <div className={styles.rightSmallerContainerText}><div className={styles.rightFont}>Transforming your world with technology for your success and joy, as we cherish our amazing customers</div>
      <div className={styles.logo}></div><div className={styles.teamFont}>- Team Dataandgrow</div></div>
      
      </div><div className={styles.rightSmallerContainer}>
    <div className={styles.rightSmallerContainerImage}></div>
      
      </div></div>
    </div>
    
        </section>
  );
};

export default AboutSection;




 // <section className={styles.aboutSection} id="about" ref={textRef}>
    //   <div className={styles.container}>
    //     <div className={styles.row}>
    //       <div
    //         className={`${styles.col12} ${styles.colLg6} ${styles.animateOnScroll} ${styles.slideInLeft}`}
    //       >
    //         <p
    //           className={`${styles.textBlue} ${styles.fontWeight200} ${styles.font20}`}
    //         >
    //           About Us
    //         </p>
    //         <h1
    //           className={`${styles.mainFont} ${styles.fontWeight600} ${styles.textBlack}`}
    //         >
             
    //           Innovating the Future of <span className={styles.bgGreen}>Science</span> and{" "}
    //           <span className={styles.bgGreen}>Technology</span>!
    //         </h1>
    //       </div>
    //       <div
    //         className={`${styles.col12} ${styles.colLg6} ${styles.animateOnScroll} ${styles.slideInRight}`}
    //       >
    //         <div
    //           className={`${styles.mlMd4} ${styles.desc1} ${styles.plMd2} ${styles.fontWeight200} ${styles.textGrey} ${styles.font18}`}
    //         >
    //           <p className={styles.black}>
    //             At Dataandgrow, we are truly excited about harnessing the power of data
    //             to spark innovation and growth! Our mission is to provide wonderful digital
    //             solutions that uplift both businesses and individuals alike.
    //           </p>
    //           <p className={styles.black}>
    //             By merging the latest technology with a genuine understanding of your needs, we craft
    //             delightful and customized solutions just for you.
    //           </p>
    //         </div>
    //       </div>
    //     </div>

    //     {/* About Boxes */}
    //     <div className={styles.row}>
    //       {/* First Box */}
    //       <div className={styles.colMd4}>
    //         <div className={`${styles.aboutBox} ${styles.bgGreen}`}>
    //           <div className={styles.aboutMainIcon}>
    //             <FaLightbulb />
    //           </div>
    //           <h5>Transforming your world with technology!</h5>
    //         </div>
    //       </div>
    //       {/* Second Box */}
    //       <div className={styles.colMd4}>
    //         <div className={`${styles.aboutBox} ${styles.bgBlue}`}>
    //           <div className={styles.aboutMainIcon}>
    //             <FaBriefcase />
    //           </div>
    //           <h5>Your success is our greatest joy!</h5>
    //         </div>
    //       </div>
    //       {/* Third Box */}
    //       <div className={styles.colMd4}>
    //         <div className={`${styles.aboutBox} ${styles.bgRed}`}>
    //           <div className={styles.aboutMainIcon}>
    //             <FaHeart />
    //           </div>
    //           <h5>We cherish our amazing customers!</h5>
    //         </div>
    //       </div>
    //     </div>

    //     {/* About Image */}
    //     <div
    //       className={`${styles.dFlex} ${styles.justifyContentCenter} ${styles.bounceInLeft}`}
    //     >
    //       {/* <img
    //         src="https://megaone.acrothemes.com/corporate-finance/images/about-plant.jpg"
    //         alt="About Us Plant"
    //         className={styles.aboutImage}
    //       /> */}
    //     </div>
    //   </div>
    // </section>