import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaCloud,
  FaMobileAlt, 
  FaLightbulb,
  FaSync,
  FaLaptopCode,
  FaServer,
  FaTools,
  FaBrain,
  FaVrCardboard,
} from "react-icons/fa";
import styles from "../../css/Services.module.css";

// ServiceCard component
const ServiceCard = ({ icon, title, description }) => {
  return (
    <div className={styles.card}>
      <div className={styles.icon}>{icon}</div>
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

// Services container component
const Services = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  return (
    <section id="services">
      <div className={styles.maincontainer}>
        <div className={styles.sectionservices}>
          <div className={`${styles.container} container`}>
            <h2 className={styles.header1}>
              Our Service:
            </h2>
            <h2 className={styles.header2}>
              Empowering Innovation Across Industries
            </h2>

            <Slider {...settings} className={styles.slider}>
  <ServiceCard
    icon={
      <FaBrain
        className={`${styles.iconImage} ${styles.iconAiMl}`}
      />
    }
    title="AI/ML"
    description="Harness the power of artificial intelligence and machine learning to unlock new business insights, automate processes, and deliver personalized customer experiences, positioning your organization ahead in the competitive digital landscape."
  />
  <ServiceCard
    icon={
      <FaVrCardboard
        className={`${styles.iconImage} ${styles.iconArVr}`}
      />
    }
    title="AR/VR"
    description="Leverage augmented and virtual reality to create immersive experiences that captivate users. Our AR/VR solutions are tailored for training, customer engagement, and brand storytelling to keep your business at the forefront of technological innovation."
  />
  <ServiceCard
    icon={
      <FaTools
        className={`${styles.iconImage} ${styles.iconTransformation}`}
      />
    }
    title="Digital Transformation"
    description="Empower your business to thrive in the digital era. We modernize legacy systems with cutting-edge technology, enabling agility, enhancing customer experiences, and driving sustainable growth for your enterprise."
  />
  <ServiceCard
    icon={
      <FaCloud
        className={`${styles.iconImage} ${styles.iconCloud}`}
      />
    }
    title="Cloud Managed Services"
    description="Our end-to-end cloud management services ensure your infrastructure is secure, optimized, and cost-effective. Let us handle the complexities of cloud operations so you can focus on strategic business priorities and growth opportunities."
  />
  <ServiceCard
    icon={
      <FaLaptopCode
        className={`${styles.iconImage} ${styles.iconIT}`}
      />
    }
    title="IT Consulting"
    description="Leverage our expert IT consulting services to navigate complex technology decisions. We ensure your IT strategies align seamlessly with your business goals, optimizing operations, boosting productivity, and driving long-term growth and innovation."
  />
  <ServiceCard
    icon={
      <FaSync
        className={`${styles.iconImage} ${styles.iconDevOps}`}
      />
    }
    title="DevOps"
    description="Accelerate your software development lifecycle with our comprehensive DevOps services. By streamlining development and operations, we help improve product quality, reduce time-to-market, and enable faster delivery of innovative solutions to clients."
  />
  <ServiceCard
    icon={
      <FaMobileAlt
        className={`${styles.iconImage} ${styles.iconMobile}`}
      />
    }
    title="Mobile App Development"
    description="Create engaging, responsive mobile apps tailored to your business needs. Our mobile development solutions enhance user experiences and help you capture new opportunities in the rapidly evolving mobile-first digital landscape."
  />
  <ServiceCard
    icon={
      <FaLightbulb
        className={`${styles.iconImage} ${styles.iconIdeation}`}
      />
    }
    title="Ideation and Design Strategy"
    description="Turn your ideas into powerful, innovative digital products with our ideation and design services. We ensure every solution is both functional and aesthetically pleasing, resonating deeply with your audience while driving business results."
  />
  <ServiceCard
    icon={
      <FaServer
        className={`${styles.iconImage} ${styles.iconSoftware}`}
      />
    }
    title="Software Development"
    description="From custom-built software to upgrading existing systems, we deliver innovative solutions that streamline processes, enhance operational efficiency, and position your business for future success in a fast-evolving digital environment."
  />
</Slider>

          </div>
        </div>
      </div>
    </section>
  );
};

// Custom Next Arrow component
const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={` ${styles.posrbtn}`} onClick={onClick}>
      <FontAwesomeIcon icon={faAngleRight} />
    </div>
  );
};

// Custom Prev Arrow component
const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={` ${styles.arrowleft} ${styles.btnposl}`} onClick={onClick}>
      <FontAwesomeIcon icon={faAngleLeft} />
    </div>
  );
};

export default Services;
