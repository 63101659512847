// src/Pages/HomePage/ContactSection.js
import React, { useState } from "react";
import styles from "../../css/ContactModule.module.css"; // Ensure the path is correct
import {
  FaMapMarkerAlt,
  FaPhoneVolume,
  FaPaperPlane,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import emailjs from "emailjs-com";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    userName: "",
    userPhone: "",
    userEmail: "",
    userMessage: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userId = "0xK1r-EyCuBbXjjuh"; // Your public User ID

    emailjs
      .send("service_zoukfh3", "template_ixm8862", formData, userId)
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        alert("Email sent successfully!");
        // Clear form
        setFormData({
          userName: "",
          userPhone: "",
          userEmail: "",
          userMessage: "",
        });
      })
      .catch((err) => {
        console.error("Failed to send email:", err);
        alert("Failed to send email. Please try again later.");
      });
  };

  return (
    <section className={styles.contactSection} id="contact">
      <div className={styles.container}>
        <div className={styles.row}>
          {/* Contact Information Section */}
          <div className={`${styles.col12} ${styles.colLg6} ${styles.section4left}`}>
            <h4 className={styles.heading}>
              Questions?
              <span className={`${styles.dBlock} ${styles.mainColor}`}>
                Let's Get In Touch
              </span>
            </h4>
            <div className={styles.contactDetails}>
              <h4 className={styles.heading}>Head Office</h4>
              <p className={styles.text}>
                Our mission is to provide the IT industry with outstanding products and services, driven by a foundation of innovation.
              </p>
              <ul>
                <li>
                  <FaMapMarkerAlt /> Manisha Building, Nehru Place, New Delhi, Delhi 110019
                </li>
                <li>
                  <FaPaperPlane /> dataandgrow@gmail.com
                </li>
              </ul>
            </div>
            <div className={styles.socialIconsWrapper}>
              <ul className={styles.socialIconsList}>
                <li>
                  <a className={styles.socialIcon} href="#">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a className={styles.socialIcon} href="#">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a className={styles.socialIcon} href="https://www.linkedin.com/company/dataandgrow?trk=blended-typeahead">
                    <FaLinkedinIn />
                  </a>
                </li>
                <li>
                  <a className={styles.socialIcon} href="#">
                    <FaInstagram />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Contact Form Section */}
          <div className={`${styles.col12} ${styles.colLg5} ${styles.offsetLg1} ${styles.section4left}`}>
            <form className={styles.contactForm} onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <input
                  type="text"
                  name="userName"
                  placeholder="Name"
                  className={styles.formControl}
                  value={formData.userName}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="userPhone"
                  placeholder="Contact No"
                  className={styles.formControl}
                  value={formData.userPhone}
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  name="userEmail"
                  placeholder="Email"
                  className={styles.formControl}
                  value={formData.userEmail}
                  onChange={handleChange}
                  required
                />
                <textarea
                  className={styles.formControl}
                  name="userMessage"
                  rows="6"
                  placeholder="Type Your Message Here"
                  value={formData.userMessage}
                  onChange={handleChange}
                  required
                ></textarea>
                <button
                  type="submit"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnRounded} ${styles.btnRed}`}
                >
                  Submit Information
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
